<template>
  <nav>
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->

    <div>data1: {{ data1 }} =</div>
    <div>data2: {{ data2 }} =</div>
    <div>data3: {{ data3 }} =</div>
    <div>加密: {{ data4 }} =</div>
    <div>解密: {{ data5 }} =</div>
    <div>用户信息: {{ data6 }} =</div>
    <button @click="handleClick">我是按钮</button>
  </nav>
  <!-- <router-view/> -->
</template>
<script>
export default {
  data() {
    return {
      data1: "data1",
      data2: "data2",
      data3: "data3",
      data4: "data4",
      data5: "data5",
      data5: "data5",
    };
  },
  methods: {
    async handleClick() {
      this.data1 = "button";
      console.log("我是按钮", window);
      if (HarmonyOsNative) {
        const res = HarmonyOsNative.hello("我是h5");
        this.data1 = JSON.stringify(Object.keys(window.HarmonyOsNative));
        this.data2 = JSON.stringify(res)

        this.data3 = HarmonyOsNative.hello;
        this.data4 = HarmonyOsNative.encryption(`{"name": 11}`)
        this.data5 = HarmonyOsNative.decrypt(this.data4)
        this.data6 = HarmonyOsNative.NavGetBaseInfo()
      }
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
